var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "searchWrapper ",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.searchList.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "status-icon": "",
                "label-position": "right",
                model: _vm.formInline,
              },
            },
            [
              _c("div", { staticClass: "search_box_title" }, [
                _vm._v(_vm._s(_vm.$t("searchModule.Query_Table"))),
              ]),
              _c("div", { staticClass: "col_box" }, [
                _c(
                  "div",
                  { staticClass: "col_left" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.region"),
                          prop: "areaId",
                        },
                      },
                      [
                        _c("a-cascader", {
                          ref: "cascader",
                          on: { change: _vm.parkClear },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.Belonging_operator"),
                        },
                      },
                      [
                        _c("a-operation-select", {
                          ref: "operationSelect",
                          on: { change: _vm.parkClear },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.Type_of_parking_lot"),
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.formInline.parkType,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "parkType", $$v)
                              },
                              expression: "formInline.parkType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "全部", value: "" },
                            }),
                            _c("el-option", {
                              attrs: { label: "路内车场", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "路外车场", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("searchModule.park_name") } },
                      [
                        _c("a-park-select", {
                          ref: "parkSelect",
                          attrs: {
                            instance: this,
                            parkTypeRefName: "parkTypeSelect",
                            operationRefName: "operationSelect",
                            areaRefName: "cascader",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "时间" } },
                      [
                        _c("a-date-picker", {
                          ref: "datePicker",
                          attrs: {
                            selectkeys: _vm.selectkeys,
                            YearShowHidden: _vm.selectkeys[3],
                            isPreviousday: _vm.isPreviousday,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col_right" },
                  [
                    _vm.$route.meta.authority.button.query
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-search",
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                _vm.pageNum = 1
                                _vm.searchList()
                              },
                            },
                          },
                          [_vm._v("查询 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "info",
                          icon: "el-icon-delete",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.empty()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.reset")))]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c("el-col", { staticClass: "list_left", attrs: { span: 16 } }, [
                _c("div", { staticClass: "divSize" }, [
                  _c("div", [
                    _c("div", { staticClass: "divIcon" }),
                    _c("span", { staticClass: "divText" }, [
                      _vm._v("停车营收汇总统计"),
                    ]),
                    _c("span", { staticClass: "div_time" }, [
                      _vm._v(
                        "时间范围: " +
                          _vm._s(_vm.startTimeShow) +
                          " 至 " +
                          _vm._s(_vm.endTimeShow)
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "status_H" }, [
                    _c("div", { staticClass: "status_info1" }, [
                      _c("div"),
                      _c("div", { staticClass: "status_info" }, [
                        _c("div", { staticClass: "status_img" }, [
                          _c("img", {
                            attrs: { src: require("./img/iconB.png"), alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "status_word" }, [
                          _c("p", [_vm._v("应收订单数（个）")]),
                          _c("p", [_vm._v(_vm._s(_vm.totalPay.shouldpayNum))]),
                        ]),
                      ]),
                      _c("div"),
                    ]),
                    _c("div", { staticClass: "status_info1" }, [
                      _c("div"),
                      _c("div", { staticClass: "status_info" }, [
                        _c("div", { staticClass: "status_img" }, [
                          _c("img", {
                            attrs: { src: require("./img/iconG.png"), alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "status_word" }, [
                          _c("p", [_vm._v("实收订单数（个）")]),
                          _c("p", [_vm._v(_vm._s(_vm.totalPay.actualpayNum))]),
                        ]),
                      ]),
                      _c("div"),
                    ]),
                    _c("div", { staticClass: "status_info1" }, [
                      _c("div"),
                      _c("div", { staticClass: "status_info" }, [
                        _c("div", { staticClass: "status_img" }, [
                          _c("img", {
                            attrs: { src: require("./img/iconS.png"), alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "status_word" }, [
                          _c("p", [_vm._v("订单实缴率")]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPay.orderPayRadio
                                  ? (_vm.totalPay.orderPayRadio * 100).toFixed(
                                      2
                                    )
                                  : "0.00"
                              ) + "%"
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div"),
                    ]),
                    _c("div", { staticClass: "status_info1" }, [
                      _c("div"),
                      _c("div", { staticClass: "status_info" }, [
                        _c("div", { staticClass: "status_img" }, [
                          _c("img", {
                            attrs: { src: require("./img/iconF.png"), alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "status_word" }, [
                          _c("p", [_vm._v("客单价（元）")]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPay.avgPrice
                                  ? _vm.priceFormate(_vm.totalPay.avgPrice)
                                  : "0.00"
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div"),
                    ]),
                  ]),
                  _c("div", { staticClass: "status_H" }, [
                    _c("div", { staticClass: "status_info1" }, [
                      _c("div"),
                      _c("div", { staticClass: "status_info" }, [
                        _c("div", { staticClass: "status_img" }, [
                          _c("img", {
                            attrs: { src: require("./img/iconY.png"), alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "status_word" }, [
                          _c("p", [_vm._v("应收金额（元）")]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPay.shouldpay
                                  ? _vm.priceFormate(_vm.totalPay.shouldpay)
                                  : "0.00"
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div"),
                    ]),
                    _c("div", { staticClass: "status_info1" }, [
                      _c("div"),
                      _c("div", { staticClass: "status_info" }, [
                        _c("div", { staticClass: "status_img" }, [
                          _c("img", {
                            attrs: { src: require("./img/iconF.png"), alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "status_word" }, [
                          _c("p", [_vm._v("实收金额（元）")]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPay.actualPay
                                  ? _vm.priceFormate(_vm.totalPay.actualPay)
                                  : "0.00"
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div"),
                    ]),
                    _c("div", { staticClass: "status_info1" }, [
                      _c("div"),
                      _c("div", { staticClass: "status_info" }, [
                        _c("div", { staticClass: "status_img" }, [
                          _c("img", {
                            attrs: { src: require("./img/iconB.png"), alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "status_word" }, [
                          _c("p", [_vm._v("金额实缴率")]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPay.moneyPayRadio
                                  ? (_vm.totalPay.moneyPayRadio * 100).toFixed(
                                      2
                                    )
                                  : "0.00"
                              ) + "%"
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div"),
                    ]),
                    _c("div", { staticClass: "status_info1" }, [
                      _c("div"),
                      _c("div", { staticClass: "status_info" }, [
                        _c("div", { staticClass: "status_img" }, [
                          _c("img", {
                            attrs: { src: require("./img/iconG.png"), alt: "" },
                          }),
                        ]),
                        _c("div", { staticClass: "status_word" }, [
                          _c("p", [_vm._v("平均泊位收入(元)")]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.totalPay.avgBerthIncome
                                  ? _vm.priceFormate(
                                      _vm.totalPay.avgBerthIncome
                                    )
                                  : "0.00"
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div"),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { staticClass: "list_right", attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "divSizeTop" },
                  [
                    _c(
                      "div",
                      { staticClass: "padding16" },
                      [_c("graphTitle", { attrs: { title: "车场排行榜" } })],
                      1
                    ),
                    _c("parkTop", {
                      attrs: {
                        parkTopList: _vm.parkTopList,
                        tabPanList: _vm.tabPanList,
                      },
                      on: { getranking: _vm.getranking },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "chartSize1" },
                  [
                    _c(
                      "graphTitle",
                      {
                        staticClass: "paddingSize",
                        attrs: { title: "应收订单数与实收订单数" },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              content:
                                "展示统计时间内，已出场的停车订单与已完成缴费的订单数量对比及趋势。按出场时间统计。未出场订单不参与统计。",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question my-icon" })]
                        ),
                      ],
                      1
                    ),
                    _c("received-order", {
                      attrs: { type: 1, chartParam: _vm.data5 },
                    }),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "chartSize1" },
                  [
                    _c(
                      "graphTitle",
                      {
                        staticClass: "paddingSize",
                        attrs: { title: "应收金额与实收金额" },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              content:
                                "展示统计时间内，已出场的停车订单应收金额与实收金额的对比及趋势。实收金额=优惠金额+实付金额-退款金额。未出场订单不参与统计",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question my-icon" })]
                        ),
                      ],
                      1
                    ),
                    _c("received-order", {
                      attrs: { type: 2, chartParam: _vm.data6 },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "chartSize" },
                  [
                    _c(
                      "graphTitle",
                      { attrs: { title: "订单实缴率" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              content:
                                "订单实缴率=实收订单数/应收订单数。该指标反应订单的实缴情况。指标越高，说明订单缴费情况越好。如一笔停车订单即有场中支付也有欠费，那么订单实缴率为0。",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question my-icon" })]
                        ),
                      ],
                      1
                    ),
                    _c("lineChart", { attrs: { orderData: _vm.data1 } }),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "chartSize" },
                  [
                    _c(
                      "graphTitle",
                      { attrs: { title: "金额实缴率" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              content:
                                "金额实缴率=实收订单金额/应收订单金额。该指标反应订单的金额实缴情况。指标越高，说明订单缴费情况越好。",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question my-icon" })]
                        ),
                      ],
                      1
                    ),
                    _c("lineChart", { attrs: { orderData: _vm.data2 } }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "chartSize" },
                  [
                    _c(
                      "graphTitle",
                      { attrs: { title: "客单价趋势分析" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              content:
                                "客单价为统计范围内每笔停车订单的平均收费金额。客单价=订单应收金额/停车订单数。可辅助评估车场的收费标准。未出场订单不参与统计",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question my-icon" })]
                        ),
                      ],
                      1
                    ),
                    _c("lineChart", { attrs: { orderData: _vm.data3 } }),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "chartSize" },
                  [
                    _c(
                      "graphTitle",
                      { attrs: { title: "平均泊位收入趋势分析" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              placement: "top",
                              content:
                                "平均泊位收入=实收金额/泊位数量。反应统计范围内车场单个泊位的平均收费情况。该指标可集合实缴率一起评估车场的收费标准和收入情况。未出场订单不参与统计",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question my-icon" })]
                        ),
                      ],
                      1
                    ),
                    _c("lineChart", { attrs: { orderData: _vm.data4 } }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }